<template>
  <div>
    <div>
      <div class="block">
        <span class="demonstration"></span>
        <el-date-picker
            v-model="value2"
            type="datetimerange"
            :shortcuts="shortcuts"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch" style="margin-left: 30px">搜索</el-button>
      </div>
      <div style="margin-top: 30px">
        <div style="color: #DD4A68;font-size: 20px;font-family: '等线 Light';font-weight: 700;">*总新增用户：{{ total }}</div>
        <br>
        <div style="color: #409EFF;font-size: 20px;font-family: '等线 Light';font-weight: 700;">
          *终身学习导师邀请：{{ totalClerk }}
        </div>
        <br>
        <div style="color: #2dc26b;font-size: 20px;font-family: '等线 Light';font-weight: 700;">*智慧父母邀请：{{ totalParents }}
        </div>
      </div>

      <div id="myChart" :style="{ height: '600px' }">
      </div>
    </div>


    <Clerk></Clerk>
    <Parents></Parents>

  </div>
</template>

<script>
import Parents from "@/views/user/Parents.vue"
import Clerk from "@/views/user/Clerk.vue"

export default {
  name: "Index",
  inject: ['reload'],
  components: {Parents, Clerk},
  data() {
    return {
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end]
        })()
      }, {
        text: '最近一个月',
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end]
        })()
      }, {
        text: '最近三个月',
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end]
        })()
      }],
      value2: [new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate(), new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()), new Date],
      startTime: '',
      endTime: '',

      total: '',
      totalClerk: '',
      totalParents: '',

      myChart: '',
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          top: 'bottom'
        },
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            dataView: {show: true, readOnly: false},
            restore: {show: true},
            saveAsImage: {show: true}
          }
        },
        series: [
          {
            name: '邀请用户',
            type: 'pie',
            radius: ['0%', '70%'],
            center: ['50%', '50%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            data: [
              {value: 7, name: '搜索引擎'},
              {value: 10000, name: '直接访问'},
            ]
          }
        ]
      },

      page: true,

      orgData: [],

      tableData: [],

      parentsData: [],

      orgId: '',
      orgName: '',

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10

    }
  },
  created() {
    this.handleSearch()
  },
  mounted() {
    setTimeout(() => {
      this.myChart = this.$echarts.init(document.getElementById('myChart'));
      this.myChart.setOption(this.option);
    }, 10)
  },
  methods: {
    handleSearch() {
      if (this.value2 != '') {
        this.startTime = this.value2[0]
        this.startTime = this.startTime.getFullYear() + '-' + (this.startTime.getMonth() + 1) + '-' + this.startTime.getDate() + ' ' + this.startTime.getHours() + ':' + this.startTime.getMinutes() + ':' + this.startTime.getSeconds()
      }
      if (this.value2 != '') {
        this.endTime = this.value2[1]
        this.endTime = this.endTime.getFullYear() + '-' + (this.endTime.getMonth() + 1) + '-' + this.endTime.getDate() + ' ' + this.endTime.getHours() + ':' + this.endTime.getMinutes() + ':' + this.endTime.getSeconds()
      }
      this.$axios({
        method: "get",
        url: "/parent/getTotal",
        params: {"startTime": this.startTime, "endTime": this.endTime, "orgId": -1}
      }).then(response => {
        this.total = response.data.datas.total
        console.log(response)
      })
      this.$axios({
        method: "get",
        url: "/parent/getByClerk",
        params: {"startTime": this.startTime, "endTime": this.endTime, "orgId": this.orgId}
      }).then(response => {
        console.log(response)
        this.option.series[0].data[0].value = response.data.datas.totalClerk * 1
        this.totalClerk = response.data.datas.totalClerk
        this.option.series[0].data[0].name = '终身学习导师邀请'
      })
      this.$axios({
        method: "get",
        url: "/parent/getByParents",
        params: {"startTime": this.startTime, "endTime": this.endTime, "orgId": this.orgId}
      }).then(response => {
        console.log(response)
        this.option.series[0].data[1].value = response.data.datas.totalParents * 1
        this.totalParents = response.data.datas.totalParents
        this.option.series[0].data[1].name = "智慧父母邀请"
      })
    },

  },
  //数据自动刷新，必然需要一个监听机制告诉Echarts重新设置数据
  watch: {
    //观察option的变化
    option: {
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.myChart.setOption(newVal);
          } else {
            this.myChart.setOption(oldVal);
          }
        } else {
          this.init();
        }
      },
      deep: true //对象内部属性的监听，关键。
    }
  },

}
</script>

<style scoped>

</style>
